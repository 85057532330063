.navbar-top span {
    padding-right: 18px;
    padding-left: 18px;
    padding-bottom: 18px;
    margin-top: 10px;
    margin-left: 4px;
    margin-right: 4px;
    color: #595d6e;
    font-weight: 500;
    min-width: 50px;
    text-align: center;
    cursor: pointer;
}

.navbar-top .active-tab {
    border-bottom: 2px solid #1a73e9;
    color: #1a73e9;
}
